define('uniqmark/pods/personalized_video/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		setupController: function setupController() {
			Ember.run.schedule('afterRender', function () {
				// Do yet more things
				// $('.scroll_smooth').click( function() {
				Ember.$('html, body').animate({ scrollTop: 0 }, 100);
				// } );
			});
		}
	});
});